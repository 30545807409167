// trigger class -----

.sm-select.mat-select {
  width: 100%;
  height: 16px;

  .mat-select-trigger {
    height: 16px !important;

    .mat-select-value,
    .mat-select-min-line {
      color: $primary-dark-color;
      @include label-main;
      font-size: 14px !important;
    }
    .mat-select-placeholder {
      color: #eaecf0;
      @include label-main;
      font-size: 14px !important;
    }
    .mat-select-arrow {
      border: none;
    }
    .mat-select-arrow-wrapper {
      visibility: hidden;
    }
  }
}
.impressions {
  .lg-select.mat-select {
    .mat-select-trigger .mat-select-placeholder {
      font-size: 17px !important;
    }
  }
}
.multi.mat-select {
  .mat-select-trigger {
    .mat-select-value {
      text-overflow: unset !important;
    }
    .mat-select-value .mat-select-value-text .mat-select-min-line {
      color: $primary-dark-color !important;
    }
  }
}

// panel class -----

.sm-panel.mat-select-panel {
  // @include sm-dropdown-container;
  max-height: max-content !important;
  overflow: hidden;
  margin-top: 60px !important;

  &.ng-animating {
    visibility: hidden !important;
  }

  .mat-option {
    padding: 0 !important;
    height: 20px !important;
    margin-bottom: 18px;
    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      .mat-pseudo-checkbox {
        background-color: $accent-red;
      }
    }
    .mat-pseudo-checkbox {
      border: 1px solid $accent-dark-red;
      box-sizing: border-box;
      border-radius: 4px;

      &::after {
        color: #eaecf0;
        top: 3.4px;
        left: 2px;
      }
    }
    .mat-pseudo-checkbox-checked {
      background-color: $accent-red;
    }
    .mat-option-text {
      color: $primary-dark-color;
      @include font-small;
    }
  }

  .mat-option.mat-selected {
    .mat-option-text {
      color: $primary-dark-color;
      @include font-small-bold;
    }
  }
}

// ad-spots-search panel class
.venue-panel.mat-select-panel {
  width: 300px !important;
  min-width: 100px !important;
  margin-left: 32px !important;
}

@media (min-width: $xl-min) {
  .venue-panel.mat-select-panel.search {
    margin-left: 0 !important;
  }
}
.venue-panel.mat-select-panel.search {
  margin-top: 44px !important;
}
.dropdown-panel.mat-select-panel {
  margin-top: 0px !important;
  margin-left: 20px;
  // min-width: 260px !important;
  position: absolute;
  top: 35px;
  border-radius: 6px;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 33%);
  min-width: 370px !important;
  left: 5px;
}

@media (max-width: $lg-max) {
  .venue-panel.mat-select-panel {
    margin-left: 24px !important;
  }
}

// mobile panel position
.mobile.mat-select-panel {
  width: 252px !important;
}

@media (max-width: 374.98px) {
  .mat-select-panel-wrap {
    width: 320px !important;
    flex-basis: auto !important;
    margin: auto;
  }
  .mobile.mat-select-panel {
    margin-left: 26px !important;
  }
}

.impressions-select {
  max-width: 250px !important;
}
