[class^="uil-"]:before,
[class*=" uil-"]:before {
  font-size: 32px;
}

[class^="s-48"]:before,
[class*=" s-48"]:before {
  font-size: 48px !important;
}
[class^="s-24"]:before,
[class*=" s-24"]:before {
  font-size: 24px !important;
}
[class^="s-21"]:before,
[class*=" s-21"]:before {
  font-size: 21px !important;
}
[class^="s-20"]:before,
[class*=" s-20"]:before {
  font-size: 20px !important;
  margin: 8px;
}
[class^="s-18"]:before,
[class*=" s-18"]:before {
  font-size: 18px !important;
}
[class^="s-16"]:before,
[class*=" s-16"]:before {
  font-size: 16px !important;
}
[class^="s-12"]:before,
[class*=" s-12"]:before {
  font-size: 12px !important;
}

img.s-21 {
  width: 21px;
  height: 21px;
  object-fit: contain;
}
img.s-16 {
  width: 16px;
  height: 16px;
  object-fit: contain;
}
