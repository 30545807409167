.hadsup-card {
  display: flex;
  cursor: pointer;
  border-radius: 12px;

  img {
    object-fit: none;
    // filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.33));
  }

  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .card-address {
      h4 {
        @include label-main-bold;
        color: #2a3845;
        font-weight: 600;
        margin: 0px;
      }
      .desc-name {
        text-decoration: none;
        color: #84929f;
        font-size: 12px;
      }
    }
    .c-title {
      color: #2a3845;
    }

    .c-subtitle {
      margin-bottom: 8px;
      color: #2a3845;
      @include font-small;
    }

    .c-desc {
      color: #2a3845;
      margin-right: 0px;
      text-align: end;
      .c-adspot-imp {
        display: block;
        font-size: 14px;
        color: #2a3845;
        font-weight: 600;
        text-align: left;
      }
    }

    .c-link {
      color: $accent-dark-red;
      cursor: pointer;
      font-size: 12px;
      font-weight: 600;
      line-height: 30px;
      border-bottom: 1px solid;
      white-space: nowrap;

      @media (max-width: 767px) {
        margin-top: 6px;
        font-size: 12px !important;
      }
    }
  }

  &:hover,
  &.active {
    background-color: $primary-light-gray-1;
  }
}

.hadsup-card--sm {
  @extend .hadsup-card;

  img {
    border-radius: 12px 0px 0px 12px;
  }

  .card-content {
    .c-title {
      @include headline4;
      font-weight: 600;
      display: flex;
      align-items: center;

      .c-title-logo {
        width: 41px !important;
        height: 21px !important;
        margin-left: 8px;
        align-items: center;
        justify-content: center;
        display: flex;
        border-radius: 12px 0px 0px 12px;
        img {
          width: 100%;
          border-radius: 0;
          height: 100%;
        }

        @media (max-width: $xs-max) {
          width: 50px !important;
        }
      }
    }

    .c-desc {
      @include font-small-bold;
      font-weight: 400 !important;

      span.white {
        color: $primary-dark-color;
        padding-left: 8px;
      }

      span.dark-red {
        color: $accent-dark-red;
        line-height: 27px;

        @media (max-width: $lg-max) {
          font-size: 14px !important;
          line-height: 1;
        }
      }

      @media (max-width: 767px) {
        width: 100%;
      }
    }

    .cost-desc {
      font-size: 28px;
      font-weight: 600;

      @media (max-width: 767px) {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        line-height: 1;
      }
    }

    .d-none-desktop {
      display: none;

      @media (max-width: $sm-max) {
        display: block;
        color: #a2b0bd;
        font-size: 12px;
      }
    }
  }

  &:hover,
  &.active {
    background-color: $primary-white;
    box-shadow: 0px 1px 2px 0px #1018280d;
    border: 1px solid #d0d5dd;
    // width: 100%;
  }

  &-hor {
    @extend .hadsup-card--sm;
    align-items: center;

    .card-content {
      width: 100%;
      margin: 0;
      max-width: 480px;
      .card-content-inner {
        width: 100%;
        padding: 16px;
        box-sizing: border-box;
      }

      .c-cost-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @media (max-width: $sm-max) {
          flex-wrap: wrap;

          .c-content-inner {
            width: 100%;

            .c-title {
              width: 100%;
              justify-content: space-between;
              padding-bottom: 5px;
            }

            .c-desc {
              display: none;
            }
          }
        }
      }

      .c-avg {
        display: flex;
        align-items: center;

        @media (max-width: $sm-max) {
          width: 100%;
          justify-content: space-between;
        }

        .c-desc {
          font-size: 12px !important;
          color: $primary-med-blue-1;
          padding-right: 10px;
          margin-right: 10px;
          border-right: 1px solid #ced8e0;
          line-height: 20px !important;

          &:last-child {
            border: none;
          }

          @media (max-width: $sm-max) {
            padding: 4px 0 0;
            margin: 0;
            border: none;
            display: flex;
            justify-content: space-between;
            color: #a2b0bd;

            &.d-none-mobile {
              display: none;
            }
          }
        }
      }
    }
  }

  &-ver {
    @extend .hadsup-card--sm;
    flex-direction: column;

    .card-content {
      margin: 8px 12px 16px;

      .c-desc.last {
        margin-top: 8px;
      }
    }
  }
}

.hadsup-card--lg {
  @extend .hadsup-card;

  img {
    border-radius: 16px;
  }

  .card-content {
    .c-title {
      @include headline4;
    }

    .c-desc {
      @include font-main;
    }
  }

  &:hover,
  &.active {
    background-color: $primary-light-gray-1;
  }

  &-hor {
    @extend .hadsup-card--lg;
    align-items: center;

    .card-content {
      margin: 24px 0 24px 16px;

      .c-link {
        margin-top: 8px;
      }
    }
  }

  &-ver {
    @extend .hadsup-card--lg;
    flex-direction: column;

    .card-content {
      margin: 16px;

      .c-desc.last {
        margin-top: 8px;
      }

      .c-link {
        margin-top: 16px;
      }
    }
  }
}

.hadsup-card--icon {
  @extend .hadsup-card;
  padding: 16px 24px;

  .card-content {
    margin-top: 8px;

    .c-link {
      margin-top: 8px;
    }
  }
}
