.region.cdk-overlay-pane,
.ad-spots.cdk-overlay-pane,
.preview-ad-spot.cdk-overlay-pane {
    max-width: 100% !important;
}

.ad-spots .mdc-dialog__container{
    background: $primary-dark-color !important;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.66);
    border-radius: 40px;
    max-height: calc(100vh - 100px);
    overflow-y: hidden;
    height: 200px;
    @media (min-width: 768px) and (max-width: 1199px) { 
        height: 300px;
    }
    @media (max-width: 767px) {
        height: auto;
    }
}

.region .mdc-dialog__container{
    padding: 0;
    border-radius: 24px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.33);
    overflow-y: hidden;
}

.preview-ad-spot .mdc-dialog__container{
    padding: 0;
    border-radius: 0;
}
