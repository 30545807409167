@import "mixins";
@import "variables";
@import "../src/assets/styles/material/index.scss";
@import "../src/assets/styles/index.scss";
@import "@angular/material/prebuilt-themes/indigo-pink.css";

html,
body {
  height: 100%;
  margin: 0;
  font-family: $primary-font;
  background-color: $primary-dark-gray-3;
  touch-action: manipulation;
}
.overflow-hidden-model {
  overflow: hidden;
}
@media (min-width: 1440px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1340px;
    margin: 0 auto;
  }
}

.main-wrapper {
  width: 1300px;
  margin: auto;
  padding: 0 15px;
}
@media (max-width: $lg-max) {
  .main-wrapper {
    width: 688px;
  }
}

@media (max-width: $sm-max) {
  .main-wrapper {
    width: 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
.app-container {
  display: flex;
  justify-content: center;
  img {
    vertical-align: middle;
    height: 20px;
  }
}
.webapp-wrapper {
  min-height: 92vh;
  @media (max-width: $lg-max) {
    padding-left: 0;
    flex-direction: column;
    overflow: hidden;
  }
  .content-wrap {
    flex: 1;
    max-width: 1340px !important;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @media (min-width: $xl-min) and (max-width: 1299px) {
      padding: 20px;
      max-width: 1080px;
    }

    @media (max-width: $lg-max) {
      max-width: 1140px;
      padding: 20px;
    }

    @media (max-width: $sm-max) {
      padding: 30px 16px;
    }
    @media (max-width: 1439px) and (min-width: 1300px) {
      max-width: 1140px;
    }
  }
}

.info-header-section {
  margin: 32px 0;
  @media (max-width: $sm-max) {
    margin: 0 0 20px;
  }
  .info-inner-button {
    display: flex;
    gap: 12px;

    @media (max-width: $sm-max) {
      margin: 15px 0 0;
      display: flex;
      justify-content: end;
    }
  }
  .info-inner-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $sm-max) {
      display: block;
      margin-bottom: 15px;
      white-space: nowrap;
    }
  }
  h1 {
    @include headline1;
    font-size: 26px;
    color: #0c111d;
    margin: 0;
    @media (max-width: $sm-max) {
      @include label-medium-bold;
    }
  }
  p {
    @include label-small;
    color: $primary-dark-gray;
    margin: 5px 0 0;
    @media (max-width: $sm-max) {
      margin: 0;
    }
  }
}
.records-found {
  background-image: url("assets/images/background-pattern-decorative.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
  width: 480px;
  height: 480px;
  @media (max-width: $sm-max) {
    width: 100%;
  }
  .records-found-inner {
    position: relative;
    top: 29%;
    transform: translateY(-50%);
    img {
      margin-left: 108px;
    }
  }
  .heading-records {
    @include font-main;
    color: #101828;
    margin: 30px 0 10px;
    font-weight: 600;
    text-align: center;
  }
  .sub-heading-records {
    @include label-small;
    font-weight: 400;
    line-height: 20px;
    color: #475467;
    width: 267px;
  }
}
.shimmer {
  height: 16px;
  opacity: 0.06;
  background-image: linear-gradient(to left, $shimmerColor);
  animation: shimmer 2s infinite linear;
  background-size: 1000px 100%;
  border-radius: 2px;

  &.shimmer2y {
    height: 36px;
    border-radius: 4px;
  }
}
/* Shimmer animation starts here */
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}
.shimmers-wrapper {
  background: $primary-white;
}
.image-section-shimmer {
  .balance-box.shimmers-wrapper {
    height: 222px !important;
  }
}
.password-update-form {
  display: block !important;
  width: 100%;
  height: auto;

  .form-card {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0px !important;

    .changePasswordForm {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      @media (max-width: $sm-max) {
        flex-wrap: wrap;
        display: block;
      }

      .form-group {
        flex: 0 1 27.3%;
        position: relative;

        .wrapper {
          position: relative;
          display: flex;
          align-items: center;
        }
      }

      .form-actions {
        margin-top: 26px;
        display: block;
        .action-btn {
          @include font-small-bold;
          line-height: 16px;
          font-weight: 400;
        }
      }
    }
  }
}

.form-container,
.password-update-form {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 93vh;

  .form-card {
    background: $primary-white;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px #1018280d;
    max-width: 400px;
    width: 100%;
    text-align: center;
    @media (max-width: $sm-max) {
      text-align: left;
      padding: 1rem;
    }
    h3 {
      margin: 0 0 0.5rem;
      @include headline3;
      @media (max-width: $sm-max) {
        @include label-medium-bold;
        text-align: center;
      }
    }
    p.alert-message {
      @include font-small-bold;
      font-weight: 400;
      color: $primary-light-gray-4;
      margin-top: 6px;
      @media (max-width: $sm-max) {
        text-align: left;
      }
    }
    p {
      margin: 0 0 1.5rem;
      @include label-main;
      color: $primary-light-gray-4;
      @media (max-width: $sm-max) {
        @include label-small;
        text-align: center;
      }
    }
    .forgot-password {
      display: flex;
      justify-content: end;
    }
    .form-group.logo {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 180px;
        height: 49px;
      }
    }
    .form-group {
      margin-bottom: 10px;
      text-align: left;
      .wrapper {
        position: relative;
        .eye-icon {
          position: absolute;
          margin: 11px;
          right: 2px;
          top: 20%;
          transform: translateY(-50%);
          cursor: pointer;
        }
      }
      .error {
        color: red;
        font-size: 12px;
        margin-top: 0.25rem;
      }

      label {
        display: block;
        margin-bottom: 0.5rem;
        color: #344054;
        @include font-small-bold;
        @media (max-width: $sm-max) {
          @include label-small;
        }
      }

      input {
        width: 100%;
        padding: 0.5rem;
        border: 1px solid #d0d5dd;
        font-weight: 400;
        @include font-small;
        border-radius: 8px;
        min-height: 40px;
        box-sizing: border-box;
        box-shadow: 0px 1px 2px 0px #1018280d;
      }
      .forgot-password {
        display: block;
        text-align: right;
        @include label-small-medium-bold;
        color: $primary-dark-color;
        text-decoration: none;
        margin: 5px 0;
      }
    }
    .page-link {
      margin: 2rem 0 0;
      text-align: center;
    }
    .action-btn {
      display: block;
      width: 100%;
      border: none;
      @include label-main;
      cursor: pointer;
      background: $primary-dark-color;
      color: $primary-white;
      margin-bottom: 1rem;
      padding: 0.75rem;
      border-radius: 8px;
      &:disabled {
        opacity: 0.5;
      }
    }

    a {
      text-decoration: none;
      color: $primary-dark-color;
      @include label-small-bold;
    }
  }
}
.analytics-header {
  .analytics-header-inner {
    h5 {
      @media (max-width: $sm-max) {
        margin: 0;
      }
    }
  }
}
.balance-box-wrap {
  margin: 0px 1px 16px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: $sm-max) {
    margin: 0px 0px 16px;
  }
  &.analytics-view-wrapper {
    .balance-box {
      @media (max-width: $md-max) {
        width: 49%;
      }
      @media (max-width: $sm-max) {
        width: 100%;
        margin: 0 0 10px;
      }
    }
  }
  &.balance-four-box {
    @media (max-width: $sm-max) {
      margin: 0;
    }

    .balance-box {
      @media (max-width: $sm-max) {
        &:last-child {
          width: 49%;
        }
      }
    }
  }

  hadsup-shimmer-box {
    flex: 1;
    margin: 0 1% 1% 0;
    &:last-child {
      margin-right: 0;
    }
    @media (max-width: $lg-max) {
      flex: 0 0 49.5%;
      margin: 0 1% 2% 0;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
    @media (max-width: $sm-max) {
      flex: inherit;
      width: 100%;
      margin: 0 10px 10px 0;
      height: auto;
      &:nth-child(2n) {
        margin-right: 0;
      }
      &:last-child {
        width: 100%;
      }
      .shimmers-wrapper {
        width: 100%;
        margin: 0;
        box-sizing: border-box;
        padding: 0;
      }
    }
  }
  .hadsup-shimmer-box.balance-box {
    border: 0;
    box-shadow: none !important;
  }
  .balance-box.shimmers-wrapper {
    padding: 0px 13px;
  }
  .balance-box {
    border-radius: 12px;
    padding: 20px 16px;
    flex: 1;
    box-shadow: 0px 1px 2px 0px #1018280d;
    border: 1px solid $primary-box-border-color;
    @include label-x-large-bold;
    background-color: $primary-box-color;
    margin: 0 1% 0 0;
    color: #0c111d;
    &.balance-box-clickable {
      cursor: pointer;
      text-decoration: none !important;
    }
    &:last-child {
      margin-right: 0;
    }

    @media (max-width: $lg-max) {
      flex: 0 0 49%;
      margin-bottom: 16px;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
    @media (max-width: $md-max) {
      flex: 0 0 44%;
    }
    @media (max-width: $sm-max) {
      flex: inherit;
      width: 49%;
      margin: 0 2% 10px 0;
      height: auto;
      box-sizing: border-box;
      &:nth-child(2n) {
        margin-right: 0;
      }
      &:last-child {
        width: 100%;
        &.last-wrap {
          width: 49%;
        }
      }
    }
    .shimmer {
      width: 200px;
      @media (max-width: $sm-max) {
        width: 100%;
      }
    }
    h3 {
      &.shimmer {
        padding-bottom: 0;
        margin-bottom: 8px;
        width: 100px;
      }
    }
    // h6 {
    //   @include label-x-small;
    //   padding-top: 4px;
    // }
    h6 {
      @include label-x-small;
      color: #999999;
      align-items: center;
      display: flex;
      justify-content: flex-end;
      line-height: 0px;
      margin: 0;
    }
    .title {
      @include label-small-medium-bold;
      margin: 16px 0 0 !important;
      @media (max-width: $sm-max) {
        margin: 5px 0 0 !important;
        font-size: 12px;
        font-weight: 400;
        color: $primary-light-gray-4;
        line-height: normal;
      }
    }
    .daily {
      margin: 2px 0 1px;
      display: inline-block;
      color: #999999;
      font-size: 12px;
      font-weight: 400;
    }

    .title-wrap {
      display: flex;
      align-items: center;
      @media (max-width: $sm-max) {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
      }
      .icon-img {
        width: 24px;
        height: 24px;
        background: linear-gradient(
          90.82deg,
          #ff63ae -1.75%,
          #fb823e 105.82%,
          #ff8f51 105.82%
        );
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        padding: 8px;
        @media (max-width: $sm-max) {
          margin-bottom: 10px;
        }
      }
    }
    .dashboard-value {
      @include label-x-large-bold;
      font-weight: 600;
      line-height: 44px;
      margin: 0;
      @media (max-width: $sm-max) {
        @include label-medium-bold;
      }
    }
  }
}

.webapp-wrapper {
  .analytics-section {
    .graph {
      .ad-graph {
        background-color: $primary-white;
      }

      .card-group {
        .item-card {
          background-color: $primary-white;
        }
      }
    }
  }

  .data-table {
    th,
    td {
      @media (max-width: $sm-max) {
        white-space: nowrap;
      }
    }
    .data-record {
      text-align: center;
      line-height: 1px;
      h5 {
        color: $primary-light-gray-4;
      }
      &:hover {
        background-color: transparent;
      }
    }
  }

  .dashboard-date-table {
    @media (max-width: $md-max) {
      overflow: auto;
    }
  }
}

// Toast Messages

.toast-container {
  .ngx-toastr {
    box-shadow: 0px 3px 6px rgba(51, 51, 79, 0.1);
    border-radius: 3px;
    position: fixed;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 15px 15px 15px 65px;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 32px;
    background-color: #fff;
    color: #33334f;
    top: 0;
    right: 0;
    line-height: 1.3;
    z-index: 1050;
  }

  .toast-success {
    background-image: url("assets/icons/success-icon.svg");
    border-left: 5px solid #00c389;
  }

  .toast-info {
    background-image: url("assets/icons/info-icon.svg");
    border-left: 5px solid #2f86eb;
  }

  .toast-warning {
    background-image: url("assets/icons/warning-icon.svg");
    border-left: 5px solid #ff9c28;
  }

  .toast-error {
    background-image: url("assets/icons/error-icon.svg");
    border-left: 5px solid #e63b3b;
  }
  .toast-title {
    font-weight: 500;
    font-size: 20px;
    color: $primary-dark-color;
    padding-bottom: 5px;
  }

  .toast-message {
    font-size: 15px;
    color: $primary-dark-color;
  }
}
.step-action-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // margin-bottom: 20px;
  .step-action-left {
    display: block;
    align-items: center;
    color: $primary-dark-color;

    h3 {
      @include font-small;
      color: $primary-dark-color;

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
    .back-button {
      border-radius: 22px;
    }
  }

  .step-action-right {
    .right-row {
      flex-direction: row;
      justify-content: space-between;
      display: flex;
      gap: 12px;
      button {
        height: 40px !important;
        @include font-small-bold;
        padding: 12px;
      }
    }
  }
}
.view-more-btn {
  display: flex;
  align-items: center;
  @include label-small-bold;
  text-decoration: none;
  border: 1px solid $primary-light-gray-2;
  border-radius: 5px;
  line-height: 20px;
  background-color: $primary-white;
  padding: 0px 10px 0px 13px;
  height: 36px;
  span {
    padding-right: 4px;
    color: #000000;
  }
  i {
    display: flex;
    color: $primary-dark-gray;
  }
}
button {
  display: flex;
  align-items: center;
  @include label-small-bold;
  text-decoration: none;
  border: 1px solid $primary-light-gray-2;
  border-radius: 8px;
  background-color: $primary-white;
  height: 40px;
  padding: 8px 16px;
  cursor: pointer;
  white-space: nowrap;
  color: #1d2939;
}
.hadsup-primary-dark {
  background-color: $primary-dark-color;
  color: $primary-white;
}
.hadsup-primary-default {
  background-color: $primary-white;
  color: $primary-dark-color;
}
.hadsup-btn-secondary {
  background-color: $accent-red;
  color: $primary-white;
}
.dashboard-section {
  .dashboard-section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: $sm-max) {
      flex-wrap: wrap;
      gap: 10px;
    }
    h5 {
      @include font-lead-bold;
      margin: 0;
      @media (max-width: 767px) {
        font-size: 20px;
        line-height: 1.2;
      }
    }

    a {
      white-space: nowrap;
      span {
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
    }
  }
  p.text-muted {
    @include label-small;
    color: $primary-dark-gray;
    margin: 0px 0 24px;
    @media (max-width: $sm-max) {
      margin: 4px 0 16px;
    }
  }
}

.required-asterisk {
  color: $accent-dark-red;
}

.dashboard-date-table,
.advance-booking-date-table {
  border-radius: 10px;
  border: 1px solid $primary-light-gray;
  overflow: hidden;
  &.no-data {
    border: 0;
  }
  .dashboard-date-table-inner {
    overflow-x: auto;
    border-radius: 12px 12px 0px 3px;
  }
  .table-pagination {
    background-color: $primary-white;
    border-top: 1px solid $primary-light-gray;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
    border-radius: 0px 0px 10px 10px;
    position: relative;
    @media (max-width: 767px) {
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 0px;
    }
    .pagination-left {
      @include font-small-bold;
      color: $primary-dark-color;
      font-weight: 500;
      position: relative;
      z-index: 1;
      margin-right: 13px;
      @media (max-width: 767px) {
        width: 100%;
        align-items: center;
        justify-content: center;
        text-align: center;
        display: flex;
        margin-top: 10px;
      }
      span {
        padding: 0 4px;
      }
    }

    .pagination-right {
      display: flex;
      align-items: center;
      column-gap: 10px;
      margin: 0px 15px;
      @media (max-width: 767px) {
        position: static;
        margin-top: 10px;
      }
      p {
        @include label-small;
        color: $primary-light-gray-4;
        cursor: pointer;
        min-width: 30px;
        min-height: 30px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: all 0.3s;
        font-weight: 600;
        &:hover,
        &.active {
          background-color: #f9edee;
          color: #e0525f;
        }
      }
      .pagination-btn {
        border: 1px solid $primary-light-gray;
        @include label-small;
        align-items: center;
        justify-content: center;
        width: 87px;
        background-color: transparent;
        font-weight: 500;
        color: $primary-dark-color;
        height: 36px;
        &:disabled {
          border: 1px solid $primary-light-gray-2;
          opacity: 0.3;
        }
      }
    }
  }
  .right-align {
    text-align: right;
  }
}

.data-table {
  font-family: $primary-font;
  border-collapse: collapse;
  width: 100%;
  caption {
    caption-side: top;
    background-color: $primary-white;
    margin-bottom: -9px;
    color: $primary-dark-color;
    text-align: left;
  }
  tr {
    border: none;
  }
  td,
  th {
    border-color: $primary-light-gray;
    padding: 0px 20px;
    text-align: left;
    border-style: solid;
    border-width: 0 1px 1px 0;
    color: $primary-light-gray-4;
    border-right-width: 0;
    font-size: 12px;
    font-weight: 500;
    height: 48px;
    white-space: nowrap;
    background-color: $primary-white;
    width: 22%;
    @media (max-width: $sm-max) {
      padding: 10px 12px;
    }
    &:last-child {
      border-right-width: 0;
    }
  }
  tbody {
    tr {
      td {
        color: $primary-dark-color;
        background: $color-light;
        cursor: auto;
        @include label-small;
      }
      &:hover {
        background-color: unset;
      }
      &:nth-child(even) {
        td {
          background: $primary-white;
        }
      }
      &:last-child {
        td {
          border-bottom-width: 0;
        }
      }
    }
  }
}
.dooh-info {
  .dooh-info-inner {
    margin: 6px 31px;
    @media (max-width: $md-max) {
      margin: 0px;
    }
  }
  ul {
    @media (max-width: $md-max) {
      padding: 0;
    }
  }
  li {
    list-style: none;
    display: flex;
    gap: 12px;
    align-items: center;
    @media (max-width: $md-max) {
      width: 350px;
    }
    @media (max-width: $sm-max) {
      width: 100%;
    }
    p {
      @include label-main;
      color: $primary-light-gray-4;
      line-height: 26px;
      @media (max-width: $sm-max) {
        margin: 0 0 20px;
      }
    }
  }
}
.search-option.calendar-option .label {
  display: none;
}
.search-option {
  background: $primary-white;
  border: 1px solid $primary-dark-gray-4;
  border-radius: 8px;
  padding: 9px;
  position: relative;
  cursor: pointer;
  .hadsup-date-picker {
    position: absolute;
    left: 0;
  }
  .selected-number {
    gap: 7px;
    @include label-small-bold;
    @media (max-width: $lg-max) {
      font-size: 13px;
    }
    .placeholder {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 7px;
      @media (max-width: $sm-max) {
        font-size: 13px;
      }
      .material-icons {
        color: $primary-dark-gray;
        font-size: 15px;
      }
    }
    .hadsup-date-picker {
      position: absolute;
      left: -15px;
    }
  }
  .select-range {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    @include label-small-bold;
    font-weight: 400;
    .material-icons {
      color: $primary-dark-gray;
      font-size: 20px;
    }
  }
}
.status-wrap {
  text-transform: capitalize;
  align-items: center;
  display: flex;
  margin-left: -11px;
  &::before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    // margin: 7px 8px;
    position: relative;
    left: 16px;
  }
  &.review::before,
  &.pending::before,
  &.blocked::before {
    color: $accent-red;
    background-color: $accent-red;
  }
  &.cancelled::before {
    color: $accent-red;
    background-color: $accent-red;
  }
  &.review::before {
    color: #2fc3e0;
    background-color: #2fc3e0;
  }

  &.scheduled::before {
    color: #2fe068;
    background-color: #2fe068;
  }
  &.archive::before,
  &.suspended::before,
  &.failed::before {
    color: $color-yellow;
    background-color: $color-yellow;
  }

  &.active::before,
  &.completed::before {
    background-color: $color-green;
  }
  &.draft::before {
    background: #fac64c;
  }
  span {
    &:first-child {
      height: 22px;
      font-weight: 500;
      font-size: 10px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0px 6px 0px 20px;
      border-radius: 42px;
      line-height: 1.5;
      font-size: 12px;
    }
  }

  &.draft,
  &.failed {
    span {
      &:first-child {
        background: rgba(250, 198, 76, 0.1);
        border: 0.5px solid #fac64c;
        color: #fac64c;
      }
    }
  }

  &.active {
    span {
      &:first-child {
        background: rgba(112, 200, 106, 0.1);
        border: 0.5px solid #70c86a;
        color: #70c86a;
      }
    }
  }

  &.pending {
    span {
      &:first-child {
        background: rgba(250, 82, 82, 0.1);
        border: 0.5px solid #fa5252;
        color: #fa5252;
      }
    }
  }
  &.cancelled {
    span {
      &:first-child {
        background: rgba(250, 82, 82, 0.1);
        border: 0.5px solid #fa5252;
        color: #fa5252;
      }
    }
  }

  &.scheduled {
    span {
      &:first-child {
        background: rgba(47, 195, 224, 0.1);
        border: 0.5px solid #2fe068;
        color: #2fe068;
      }
    }
  }
  &.review {
    span {
      &:first-child {
        background: rgba(47, 195, 224, 0.1);
        border: 0.5px solid #2fc3e0;
        color: #2fc3e0;
      }
    }
  }
  &.blocked {
    span {
      &:first-child {
        background: rgba(250, 82, 82, 0.1);
        border: 0.5px solid #fa5252;
        color: #fa5252;
      }
    }
  }

  &.completed {
    span {
      &:first-child {
        background: rgba(112, 200, 106, 0.1);
        border: 0.5px solid #70c86a;
        color: #70c86a;
      }
    }
  }

  &.archive {
    span {
      &:first-child {
        background: rgba(250, 198, 76, 0.1);
        border: 0.5px solid #fac64c;
        color: #fac64c;
      }
    }
  }
  @media (max-width: $lg-max) {
    > span {
      display: inline-flex;
    }
  }
}
.button-loading {
  position: relative;
  width: 100%;
  min-width: 80px;
  &::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: #fff;
    border-radius: 50%;
    animation: button-loading-spinner 0.6s ease infinite;
  }
  .button-text,
  span {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}
.breadcrumb {
  background-color: transparent !important;
  display: flex;
  color: $primary-light-gray-1;
  gap: 8px;
  padding: 0;
  font-size: 14px;
  align-items: center;
  a.current {
    color: $primary-dark-color;
    font-weight: 600;
  }
  img {
    height: 15px;
  }
  i {
    color: $primary-dark-gray-4;
    margin: -8px;
  }
  a {
    cursor: pointer;
    text-decoration: none;
    color: #485663;
  }
}

.hightChart-custom-tooltip {
  padding: 6px 8px;
  font-family: $primary-font;
  border-radius: 5px;
  .tooltip-title {
    font-weight: 500;
    margin-bottom: 5px;
    font-size: 12px;
    &:last-child {
      margin-bottom: 0px;
    }
  }

  .tooltip-separator {
    border-top: 1px solid #ccc;
    margin: 5px 0;
  }

  .tooltip-item {
    margin: 5px 0;
    @include font-small;
  }

  .legend-color {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 5px;
    vertical-align: middle;
    border-radius: 50%;
  }
}
.component-filters {
  .row {
    margin: 0 -12px;

    @media (max-width: $sm-max) {
      flex-direction: column;
    }

    .col {
      padding: 0 12px;

      .light-outline-btn {
        height: 48px;
      }
    }
    .filter-right {
      display: flex;
      gap: 14px;
    }
  }
}

.component-filters {
  .component-header-left {
    h5 {
      @include headline5;
    }
    p {
      color: $primary-dark-gray;
      margin-bottom: 10px;
    }
  }
  .dropdown-menu {
    border: none;
    box-shadow: 0px -7px 24px rgb(0 0 0 / 10%);
    border-radius: 6px;
    padding: 8px 0;

    .mat-checkbox-label {
      font-size: 16px;
      font-weight: 500;
      padding-left: 7px;
      color: $primary-light-gray;
      @media (max-width: 1199px) {
        font-size: 14px;
      }
      &:hover {
        color: $accent-dark-red;
      }
    }

    .mat-checkbox-checkmark {
      border-color: $accent-red;
      border-radius: 4px;
    }

    .dropdown-item {
      padding: 8px 16px;
    }
  }
}

// map
.sort-by {
  .dropdown-select {
    .button-style {
      font-size: 14px;
      align-items: center !important;
      background-color: transparent;
      border: 1px solid #a2b0bd;
      border-radius: 6px !important;
      justify-content: space-between;
      height: 48px !important;

      @media (max-width: $sm-max) {
        //    width: 116px;
        margin-left: auto;
      }
    }
  }

  .dropdown-select .dropdown-menu {
    max-height: 290px !important;
    padding: 15px 0px !important;
  }
}
.select-option-wrap {
  span {
    font-size: 14px;
    margin-bottom: 5px;
    display: inline-block;
    line-height: normal;
    color: #000;
  }
  button {
    span {
      margin-bottom: 0;
    }
  }
}
.mat-mdc-tooltip-surface.mdc-tooltip__surface {
  background: $accent-red !important;
}
/* Overlay Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 1040;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 100%;
  max-height: 100%;
  overflow-y: auto;
  display: none;
  &.withdraw-modal {
    width: 95%;
    max-width: 498px;
    @media (max-width: $sm-max) {
      width: 98%;
    }
    .modal-header {
      padding: 20px 0px 14px !important;
      .close {
        top: 6px !important;
      }
    }
    .transferButton {
      margin-bottom: 15px;
    }

    @media (max-width: $sm-max) {
      .modal-header {
        margin-bottom: 15px;
        h5 {
          text-align: left;
        }
      }
    }

    .modal-content {
      width: 100%;
      padding: 0 16px;
      box-sizing: border-box;
    }
    .content-wrap {
      padding: 0;
    }
    .modal-footer {
      flex-direction: row;
      padding: 15px 0;
    }
  }
  .modal-dialog {
    margin: 0;
    padding: 0;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    border-style: solid;
    border-width: 0 0 1px;
    border-color: #dee2e6;
  }
  .modal-footer {
    border-width: 1px 0 0px;
    padding-bottom: 32px;
  }
  .modal-body {
    padding: 24px 16px 16px;
    ul {
      list-style: none;
      display: inline;
      li {
        display: flex;
        justify-content: space-between;
        padding: 8px 0;
        font-size: 14px;
        border-bottom: 1px solid #f2f2f2;
        span {
          &:first-child {
            @include label-small-bold;
            color: $primary-dark-color;
          }

          &:last-child {
            color: $primary-dark-color;
            font-weight: 400;
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }
      .discount {
        cursor: pointer;
      }
    }
    .hadsup-input-wrap {
      margin-bottom: 16px;
      .label {
        @include font-small-bold;
        margin-bottom: 5px;
        display: inline-block;
      }
      .required-asterisk {
        color: $accent-red;
      }
      .input {
        border-radius: 12px;
        padding: 9px 16px;
        @include font-small;
        border: 1px solid #e7e7e7;
        color: $primary-dark-color;
        width: 100%;
        box-sizing: border-box;
      }
    }
  }

  .close {
    font-size: 1.5rem;
    color: #000;
  }

  .modal-content {
    width: 498px;
  }

  @media (max-width: 768px) {
    .modal-dialog {
      margin: 10px;
    }

    .modal-content {
      width: 100%;
    }
    .modal-body {
      padding: 0;
    }
  }

  @media (max-width: 576px) {
    .modal-dialog {
      margin: 5px;
    }

    .modal-content {
      width: 298px;
    }

    .modal-header,
    .modal-footer {
      flex-direction: column;
      align-items: center;
    }

    .modal-title {
      font-size: 18px;
      text-align: center;
      white-space: nowrap;
    }

    .modal-body p {
      font-size: 14px;
      text-align: center;
    }
  }
  &.show {
    display: block;
  }
  &.close {
    display: none;
  }
  .modal-header {
    h5 {
      margin: 0;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: normal;
      font-weight: 600;
    }
  }
  .close {
    border: none;
    padding: 0;
    position: absolute;
    right: 15px;
    top: 2px;
    line-height: normal;
  }
  .error {
    color: #e63b3b;
    font-size: 12px;
    font-weight: 600;
  }
}

/*******custom**checkbox****/

.checkbox {
  input {
    display: none;

    &:checked ~ .checkbox-label {
      &::before {
        background-color: $primary-default-dark;
      }
      &::after {
        display: block;
      }
    }
  }
  .checkbox-label {
    padding-left: 25px;
    position: relative;
    @include font-small-bold;
    color: $primary-default-dark;
    white-space: nowrap;
    cursor: pointer;
    &::before {
      position: absolute;
      left: 0;
      top: 0;
      width: 15px;
      height: 15px;
      border-radius: 3px;
      border: 1px solid $primary-default-dark;
      content: "";
      background-color: transparent;
    }
    &:after {
      left: 5px;
      top: 1px;
      width: 4px;
      height: 10px;
      border: solid $primary-white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      content: "";
      display: none;
      background: none;
    }
  }
}

.mdc-checkbox
  .mdc-checkbox__native-control:focus:checked
  ~ .mdc-checkbox__ripple {
  opacity: var(
    --mdc-checkbox-selected-focus-state-layer-opacity,
    var(--mat-app-focus-state-layer-opacity)
  );
  background-color: #f46872 !important ;
}

html .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background {
  border-color: #f46871 !important;
  background-color: #f46871 !important;
}
.mdc-checkbox__background {
  border-radius: 3px !important;
  border: 1px solid #d0d5dd !important;
}
.mdc-checkbox .mdc-checkbox__native-control:focus ~ .mdc-checkbox__ripple {
  background-color: transparent !important;
}
#custom-map {
  width: 100%;
  .map-container {
    width: 100% !important;
    height: 100% !important;
  }
}
.map-container {
  .gm-style-iw-c {
    padding: 0 !important;
    border-radius: 12px;
  }

  .gm-style-iw-d {
    box-sizing: border-box;
    overflow: visible !important;
  }

  .gm-style-iw-t::after {
    background: linear-gradient(
      45deg,
      rgb(42 56 69) 50%,
      rgba(255, 255, 255, 0) 51%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .gm-ui-hover-effect {
    opacity: 1;
    top: -5px !important;
    right: -4px !important;

    span {
      background-color: #fff;
      padding: 1px;
      border-radius: 12px;
    }
  }

  .dark-btn {
    position: absolute;
    top: 20px;
    z-index: 10;
    right: 20px;

    span {
      padding-left: 8px;
    }

    @media (max-width: 767px) {
      top: 10px;
      right: 10px;
      padding: 0;
      height: 40px;
      width: 40px;

      svg {
        path {
          fill: #fff;
        }
      }

      span {
        display: none;
      }
    }
  }
}

.gm-style div[aria-hidden="true"] {
  font-size: 12px;
  font-weight: 500;
  color: $primary-dark-color;
  padding: 0 0;
}

// map style
.gm-style div {
  color: $primary-dark-color;
  font-weight: bold;
}

.details-card-wrap {
  width: 244px;
  height: 100%;
  margin: auto;
  padding: 10px;
  font-family: "Inter", sans-serif;
  background: $primary-white !important;
  border-radius: 12px;
  color: $primary-dark-color;
  box-shadow: 0px 1px 2px 0px #1018280d !important;
  font-weight: 700;
  cursor: pointer;
  padding: 15px 16px 10px;
  box-sizing: border-box;
  .content-wrap-box {
    width: 50%;
  }
}

.gm-style-iw-d {
  .details-card-wrap {
    background: $primary-white;
    margin-top: 0px;

    &.details-card-info {
      padding: 0 !important;
      background: linear-gradient(
        109.54deg,
        #485267 -11.65%,
        #22233f 99.07%
      ) !important;
      .map-marker-menu {
        color: #fff;
      }
    }
    .map-marker-menu {
      .details-card-wrap {
        padding: 16px 20px 10px !important;
      }
    }
  }
}

.gm-style-iw-d {
  div {
    .details-card-wrap {
      background: $primary-light-gray-4;
    }
  }
}

.details-card {
  min-height: 159px;
  position: relative;
  .card-image {
    position: absolute;
    right: 3px;
    top: 44%;
    img {
      height: 20px;
      border-radius: 15px;
    }
  }
  @media (max-width: $sm-max) {
    min-height: 115px;
  }

  .image-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.card-nisa {
  width: 100%;
  border-radius: 6px;
  cursor: pointer;
}

.map-view-wrap {
  .card-heading-wrap {
    display: flex;
    flex-wrap: wrap;

    .cart-heading-inner {
      display: flex;
      align-items: center;
      width: 100%;
      flex: inherit;
      justify-content: space-between;
      .card-heading {
        font-size: 13px;
        white-space: normal;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        width: 100%;
      }
      h4 {
        color: $primary-dark-color;
        margin: 0;
        white-space: nowrap;
      }

      img {
        margin-left: 5px;
      }

      p {
        color: $accent-red;
        text-align: right;
        width: 100%;
        margin: 0;
        @include font-main;
        font-weight: 600;
      }
    }
  }

  .card-content {
    display: flex;
    flex-wrap: wrap;

    .content-wrap-box {
      width: 50%;
      margin: 5px 0;
      .item-wrap {
        display: flex;
        align-items: center;
        gap: 5px;
        img {
          height: 12px;
          width: 12px;
        }
      }
      .content-desc {
        text-align: left;
        font-size: 10px;
        margin: 0 0 5px;
      }

      .content-title {
        font-size: 8px;
        margin: 0;
      }
    }
  }
}

.card-heading {
  font-size: 17px;
  line-height: 141%;
}

.card-heading-wrap img {
  width: 50px;
}

.card-heading-wrap {
  margin-top: 12px;
}

.content-title,
.content-desc {
  margin-top: 4px;
  font-size: 14px;
  line-height: 143%;
}

.content-desc {
  text-align: right;
  color: $primary-dark-color;
}

.content-title {
  color: #a2b0bd;
}

.card-content {
  margin-top: 5px;
}

.loading-map {
  position: absolute;
  z-index: 2;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  display: flex;
  align-items: center;

  .spinner-grow {
    height: 8px;
    width: 8px;
    margin: 0 4px;
    color: $primary-light-gray;
    background-color: $primary-dark-gray;
    border-radius: 50%;
    opacity: 0;
    animation: spinner-grow 0.75s linear infinite;
  }

  @keyframes spinner-grow {
    0% {
      transform: scale(0);
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
}
.map-container {
  position: relative;

  .gm-style-iw-c {
    padding: 0 !important;
    border-radius: 12px;
    overflow: visible !important;
  }

  .gm-style .gm-style-iw {
    font-weight: 300;
    font-size: 13px;
    overflow: visible !important;
    max-width: 244px !important;
  }

  .gm-style-iw-d {
    box-sizing: border-box;
    overflow: visible !important;
  }

  .gm-style-iw-t::after {
    background: linear-gradient(
      45deg,
      rgb(42 56 69) 50%,
      rgba(255, 255, 255, 0) 51%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  // .gm-style-iw-t::after {
  //   background: $primary-white;
  // }

  .gm-style-iw-tc::after {
    background: $primary-white;
    display: none;
  }
  .gm-style-iw-ch:empty {
    padding: 0;
    height: 0;
  }
  .gm-ui-hover-effect {
    width: 20px !important;
    height: 20px !important;
    opacity: 1;
    top: -5px !important;
    right: -4px !important;
    background-color: #fff !important;
    box-shadow: 0px 0px 5x #000;
    position: absolute !important;
    z-index: 1;

    span {
      background: $primary-dark-color;
      padding: 1px;
      border-radius: 12px;
      margin: -1px !important;
      height: 20px !important;
      width: 20px !important;
    }
  }
}

.gm-style div[aria-hidden="true"] {
  font-size: 12px !important;
  font-weight: 500;
  color: #fff !important;
  padding: 10px 0 10px 20px;
  min-width: 114px;
  overflow: visible !important;
  text-align: center;
}

.map-marker-menu {
  position: relative;
  min-width: 114px;
  padding: 10px;
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
}

.map-marker-menu-sub {
  display: none;
  position: absolute;
  left: 95px;
  top: -25px;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  margin: 12px 16px;
  z-index: 9999999;
}

.map-marker-menu:hover .map-marker-menu-sub {
  display: block;
  left: -40px;
  top: 20px;
}

.map-marker-menu-sub-cart {
  border: 2px solid #e0525f;
}
.mat-mdc-dialog-inner-container {
  .dateSlotModal {
    display: flex;
    flex-direction: column;
    height: 100%;
    .modal-header {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      border-style: solid;
      border-width: 0 0 1px;
      border-color: #dee2e6;
      .close {
        border: 0;
        font-size: 1.5rem;
        color: #000;

        padding: 5px;
        position: relative;
        right: -12px;
      }
      h5 {
        margin: 0;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: normal;
        font-weight: 600;
      }
    }
    .nav-item {
      ul {
        li {
          ::marker {
            list-style: none;
          }
        }
      }
    }
    .modal-footer {
      display: flex;
      border-top: 1px solid $primary-light-blue;
      padding: 16px;
      column-gap: 10px;

      .btn {
        border-radius: 40px;
        padding: 12px;
        border: none;

        &.clear-btn {
          background-color: transparent;
          color: #2a3845 !important;
          border: 1px solid #a2b0bd;
        }

        &.apply-btn {
          background-color: $accent-dark-red;
          color: $primary-white;
          font-weight: 600;
        }
      }

      button {
        width: 48%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        opacity: 1;
        font-weight: 500;
        border-radius: 20px;
      }
    }
    .device-slot-wrap {
      height: calc(100vh - 300px);
      // margin-right: -10px;
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.2);
        border-radius: 10px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar {
        width: 8px;
        min-height: 30px;
        max-height: 30px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(99, 99, 99, 0.1);
        background-color: #2a3845;
      }
      .device-slot {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0px 10px 10px 0px;
        width: 100%;

        .device-date-slot {
          width: 50%;
          text-align: left;

          h4 {
            font-size: 17px;
            font-family: "Inter";
            font-weight: 500;
            text-transform: capitalize;
            margin: 0;
          }

          p {
            font-size: 14px;
            color: $primary-default-dark;
            margin-top: 2px;
          }
        }

        .device-qty-slot {
          display: flex;
          align-items: center;
          min-width: 40px;

          .qty-form {
            width: 100%;
            display: flex;
            align-items: center;

            span {
              border: 1px solid #a2b0bd;
              border-radius: 8px;
              display: inline-flex;
              width: 30px;
              height: 30px;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              img {
                width: 18px;
              }
              &.diabled {
                opacity: 0.5;
              }
            }

            p {
              width: 40px;
              text-align: center;
              font-size: 20px;
              color: $primary-default-dark;
            }
          }
        }
      }
    }
    .modal-body {
      width: 448px;
      max-width: 100%;
      min-height: 560px;
      margin: 0 auto;
      overflow-y: auto;
      flex: 1;
      overflow-x: hidden;
      padding: 0 15px;
      height: calc(100vh - 174px);
      box-sizing: border-box;

      @media (max-width: $sm-max) {
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;
        height: calc(100vh - 144px);
        min-height: inherit;
      }

      ul {
        width: 100%;
        padding: 16px 0 0;
        display: flex;
        list-style: none;
        @media (max-width: $sm-max) {
          padding: 0px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 29px 0;
        }
        .custom-tabs {
          display: flex;
          gap: 10px;
        }

        li {
          ::marker {
            list-style: none;
          }
          width: 50%;
          display: block;

          a {
            font-weight: 600;
            text-align: center;
            padding: 10px;
            border: 1px solid #a2b0bd;
            color: $primary-dark-color;
            text-decoration: none;
            width: 100%;
            box-sizing: border-box;
            max-width: 100%;
            height: 44px;
            align-items: center;
            justify-content: center;
            display: inline-flex;
            overflow: hidden;

            &.active {
              border: 1px solid #f46872;
              color: #fff;
              background-color: #f46872;
            }

            &.tab-disabled {
              cursor: not-allowed;
            }
          }
        }

        li:nth-child(1) {
          a {
            float: right;
            border-radius: 12px 0px 0px 12px;
          }
        }

        li:nth-child(2) {
          a {
            float: left;
            border-radius: 0px 12px 12px 0px;
          }
        }
      }

      .mat-calendar-body-cell {
        .mat-calendar-body-cell-content {
          height: 80% !important;
          width: 80% !important;
          font-size: 14px;
          font-weight: 700;
        }

        &.selected {
          .mat-calendar-body-cell-content {
            color: $primary-white !important;
            background-color: $accent-red;
            border-radius: 50%;
            font-weight: 700;
          }
        }
      }

      .mat-calendar-body-cell.available-date:hover {
        .mat-calendar-body-cell-content {
          color: #fff;
          font-weight: 700;
          background-color: #f46872;
        }
      }

      .mat-calendar-body-cell.other-available-date
        .mat-calendar-body-cell-content {
        color: $primary-light-blue !important;
      }

      .mat-calendar-body-cell-content {
        color: $primary-dark-color;
      }
      @media (hover: hover) {
        .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
          > .mat-calendar-body-cell-content:not(
            .mat-calendar-body-selected
          ):not(.mat-calendar-body-comparison-identical) {
          background-color: #f46872 !important;
          color: $primary-white;
        }
      }
      .mat-calendar-controls {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .mat-calendar-spacer {
          display: none;
        }

        .mat-calendar-previous-button {
          color: $primary-dark-color;
          order: -1;
        }

        .mat-focus-indicator {
          @include font-main-bold;
        }
      }

      .other-available-date .mat-calendar-body-cell-content:before,
      .mat-calendar-table-header-divider::after {
        display: none;
      }

      .mat-calendar-table-header {
        th {
          font-size: 14px;
        }
      }

      .mat-calendar-table {
        font-family: "Inter", sans-serif;
      }

      .mat-calendar-content,
      .mat-calendar-header {
        padding: 0 !important;
      }
    }
  }
}
.legend-color-wrap {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  align-items: center;
  @media (max-width: $sm-max) {
    font-size: 12px;
  }
  span.legend-color-inner {
    margin: 1px 8px;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    display: block;
  }
}
.withdraw-form {
  padding: 10px 2px 0px !important;

  .hadsup-input-wrap .input {
    border-radius: 8px !important;
  }
  li.nav-item {
    border-bottom: 0px !important;
  }
  .dropdown-menu {
    position: absolute;
    left: 0% !important;
    margin-top: 2px !important;
    overflow: hidden;
    height: 35px;
    div {
      padding: 5px 0px;
      a {
        display: flex;
        padding: 0px 13px !important;
        :hover {
          background-color: transparent;
        }
      }
    }
  }
}
.discount-form {
  padding: 0px 16px 16px !important;
  .row-title {
    @include font-small-bold;
    margin-bottom: 4px;
  }
}
// Create campaign Box css
.campaign-body {
  .topLocations {
    width: 88% !important;
    @media (max-width: $sm-max) {
      width: 86% !important;
    }
  }
  .spots-mobile {
    @media (max-width: $sm-max) {
      order: 2;
    }
  }
  .search-header {
    padding: 0 !important;
  }
  .content-wrap {
    @media (max-width: $sm-max) {
      padding: 0;
    }
  }
  .search-back {
    position: static !important;
    padding: 0 !important;

    .label {
      font-size: 14px;
      color: $primary-dark-blue;
    }
  }
  .calendar-option {
    @media (max-width: $sm-max) {
      padding: 0px !important;
      .calendar-inner {
        white-space: nowrap;
      }
    }
  }
  .search-list {
    margin-top: 0;
    display: block;
    border-bottom: 0 !important;

    .left-container {
      margin: 0;
      float: none;
      max-width: 100%;
      width: 100%;
      padding: 0;
      .filter-sortby {
        padding-top: 10px;
        @media (max-width: $sm-max) {
          padding-top: 0;
        }
      }
      .result-wrap {
        width: 100%;

        .results {
          max-width: 100%;
        }
      }
    }

    .right-container {
      width: 100%;
      position: static;
      margin: 0;
      height: 400px;
    }
  }

  .campaign-search-box {
    .section-subheading {
      color: $primary-light-blue;
      @include font-small;
      margin-bottom: 0px;
    }

    .search-back {
      background: $primary-white;
    }

    .main-wrapper {
      padding: 0;
      width: 100%;

      @media (max-width: $sm-max) {
        max-width: 100%;
        margin-bottom: 16px;
      }

      .label {
        color: #1d2939 !important;
        @include font-small;
        font-weight: 400 !important;
      }

      .content-wrapper .wrapper {
        position: relative;
        bottom: 4px;
      }

      .content-wrapper {
        flex-wrap: wrap !important;
        margin: 0 -8px;
        display: flex;
        .content-action-right {
          width: 65%;
          @media (max-width: $sm-max) {
            padding: 0px !important;
            display: flex !important;
            flex-wrap: wrap;
            width: 100%;
          }
        }
        .content-action-left {
          gap: 20px !important;
          @media (max-width: $sm-max) {
            margin-top: 0px;
            overflow: hidden;
          }
          .hadsup-dropdown {
            color: $primary-dark-blue !important;
          }
          .label-filter {
            color: #1d2939;
            @include font-small;
            font-weight: 400;
            @media (max-width: $sm-max) {
              white-space: nowrap;
            }
          }
        }
        .location,
        .wrapper {
          width: 50% !important;
          padding: 0 8px !important;
          @media (max-width: $sm-max) {
            width: 100% !important;
          }
          .search-option {
            @media (max-width: $sm-max) {
              margin-top: 0px !important;
            }
            .hadsup-date-picker {
              position: absolute;
              top: 30px;
              left: 0;
              margin: 0;
            }
          }
        }

        .google-maps,
        .selected-number {
          padding: 0 16px;
          border: 1px solid #d0d5dd;
          box-shadow: 0px 1px 2px 0px #1018280d;
          border-radius: 12px !important;
          height: 44px !important;
          color: #0c111d;
          font-size: 14px !important;
          font-weight: 400;
          @media (max-width: $sm-max) {
            max-width: 100%;
            height: 46px;
            top: 15px;
            margin-top: 1px;
          }
        }

        .selected-number {
          position: relative;
          top: 0px;
          display: flex;
          align-items: center;
          border-radius: 12px;
        }

        .advanced-filters {
          .selected-number {
            border: none;
            padding: 0;
            position: absolute;
            color: $primary-dark-blue;

            .selector-dropdown {
              margin-top: 2px;
            }
          }

          .wrapper {
            display: flex;
            width: 100%;
          }
        }

        .form-actions {
          margin: 20px 0 0;
          padding-left: 10px;
          width: auto;
          .hadsup-dropdown {
            height: 40px;
            color: $primary-dark-blue;
            padding: 0;
            margin: 0;
            transition: all 0.3s;
            text-transform: capitalize;

            &:hover,
            &.active {
              color: $accent-red;
              background-color: transparent;
            }

            i {
              pointer-events: none;
            }
          }
        }

        .btn-block {
          background: transparent !important;
          color: $accent-dark-red !important;
          border: 1px solid $accent-dark-red !important;
          min-width: auto !important;
          padding: 0 45px !important;
        }
      }

      .selected-spots {
        .links {
          margin-top: 0 !important;
        }
        .title {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .right-button {
            float: none;
            width: 50%;
          }
        }

        .card-area-row {
          width: 100%;
          padding: 0;

          .card-area {
            width: 100% !important;
            .c-total-top-box {
              margin: 18px 0px;
            }
            .card-area-inner {
              display: flex;
              flex-wrap: wrap;
              column-gap: 24px;

              &:after {
                display: none;
              }

              .hads-up-box {
                width: calc(50% - 12px);
                margin: 0 0 24px;
                @media (max-width: $sm-max) {
                  margin: 0;
                }
                .card-content-inner {
                  padding: 16px !important;
                }

                @media (max-width: $xs-max) {
                  width: 100%;
                }

                .c-desc.last {
                  text-align: right;

                  .cost-desc {
                    .dark-red {
                      font-size: 14px;
                      color: $primary-dark-blue;
                    }
                  }
                }

                .c-avg {
                  .c-desc {
                    display: flex;
                    border: none;
                    padding: 0;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    margin: 0;
                  }

                  .c-desc:last-child {
                    display: none;
                  }
                }
              }
            }

            .card-product-img {
              img {
                border-radius: 4px;
                // padding: 11px 0px 11px 11px;
                box-sizing: border-box;
              }
            }
          }
        }
      }
    }

    .ad-duration-section {
      display: none;
    }

    .step-action-wrap {
      border-top: 1px solid #ced8e0;
      padding: 16px 0 16px;
      margin-top: 50px;
      .step-action-right {
        .primary-lg-btn {
          background-color: $accent-red !important;
          color: $primary-white !important;
          border-radius: 50px;
          &:disabled {
            opacity: 0.5;
          }
        }
      }
    }

    .selector-dropdown {
      left: -8px;
      top: 51px;
    }
  }
  .ad-visual-wrapper {
    .section-subheading {
      margin-bottom: 16px;
    }
    .step-action-left {
      button {
        border-radius: 20px;
        color: $primary-dark-color;
      }
    }
  }

  .advanced-filters {
    .settings.show {
      max-height: inherit;
      top: 3px;
    }
  }
  .hadsup-input-wrap {
    position: relative;
    margin-bottom: 16px;

    .input,
    .dropdown-select .dropdown-toggle {
      height: 44px;
      border: 1px solid $primary-light-blue;
      border-radius: 12px;
      color: $primary-dark-blue;
      width: 100%;
      padding: 16px;
      justify-content: flex-start;
      box-sizing: border-box;
      color: #1d2939;
      @include font-small;
      font-weight: 400;
      box-shadow: 0px 1px 2px 0px #1018280d;
      @media (max-width: $sm-max) {
        height: 46px;
      }

      &::placeholder {
        color: $primary-light-blue;
      }

      &:focus {
        + .label {
          color: $accent-cyan;
        }
      }
    }

    textarea.input {
      height: 200px;
      resize: none;
    }

    .label {
      position: absolute;
      left: 17px;
      top: 8px;
      pointer-events: none;
      @include font-small-bold;
      color: $primary-med-blue-1;
      line-height: 14px !important;
    }
  }
  .table-pagination {
    margin: 20px 0 21px !important;
    .pagination-left {
      white-space: nowrap;
    }
  }
  .card-inner-wrap {
    display: flex;
    gap: 20px;
    width: 100%;
    flex-wrap: wrap;
    @media (max-width: $xxl-min) {
      flex-wrap: nowrap;
    }
    @media (max-width: $sm-max) {
      flex-wrap: wrap;
    }
  }
  .hadsup-card--sm-hor:last-child {
    margin: 0px 0px 25px !important;
    @media (max-width: $sm-max) {
      margin: 0 !important;
    }
  }
  .hadsup-card--sm-hor .card-content .c-desc {
    text-align: start;
  }

  .button-loading::after {
    border-top: 4px solid $accent-dark-red !important;
    border: 4px solid #ccc;
  }
  .no-data {
    height: 275px !important;
  }
}
.hadsup-btn-primary {
  background-color: $accent-red;
  color: $primary-white;
}
.custom-marker {
  position: relative;
  display: inline-block;
}

.marker-title {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-32%);
  padding: 2px 5px;
  border-radius: 3px;
  z-index: 1;
  font-size: 12px;
  white-space: nowrap;
  color: $primary-white !important;
  top: 11px;
}

.custom-marker img {
  z-index: 0;
}
.mat-mdc-dialog-surface {
  @media (max-width: $sm-max) {
    overflow: inherit;
  }
}
.graph-header {
  .chart-tittle {
    @include label-main-bold;
    line-height: 24px;
  }
  .chart-legend {
    @include label-small-bold;
    font-weight: 400;
    display: flex;
    @media (max-width: $sm-max) {
      font-size: 12px;
      flex-wrap: wrap;
    }
  }
}
.component-filters-row {
  .dropdown-item {
    padding: 0px 16px !important;
  }
}
.campaign-history-form-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 900px;
  .campaign-history-form {
    width: 100% !important;
    min-width: 498px !important;
    .dashboard-date-table {
      .data-table {
        border: 0px solid #eaecf0 !important;
        box-shadow: none !important;
        tbody,
        tr {
          &:last-child {
            border: 0px !important;
          }
        }
      }
    }
  }
}
.history-campaign {
  cursor: pointer;
  width: 20%;
  color: $accent-dark-red;
  &.disabled {
    cursor: default;
    color: $primary-dark-color;
  }
}
.transaction-type-wrap {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 16px;
  background-color: #ececec;
  border: 1px solid $primary-dark-gray-4;
  text-align: center;
  font-size: 12px;
  &::before {
    content: "";
    margin-right: 2px;
    font-size: 10px;
    background-color: $primary-dark-gray;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
  }
}
.dashboard-section-header-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  .search-option {
    padding: 8px 16px;
  }
}
.add-form-wrapper {
  background-color: $primary-white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  .form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    flex-wrap: wrap;

    @media (max-width: $sm-max) {
      margin-bottom: 0;
    }
  }

  .form-group {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 15px;
    margin-bottom: 1rem;

    @media (max-width: $sm-max) {
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    label {
      display: block;
      margin-bottom: 0.5rem;
      color: $primary-dark-color;
      @include font-small-bold;
    }

    input {
      width: 100%;
      padding: 0.5rem;
      border: 1px solid #D0D5DD;
      border-radius: 8px;
      min-height: 40px;
      box-sizing: border-box;
      box-shadow: 0px 1px 2px 0px #1018280D;
      @include font-small;
    }

    .error {
      color: red;
      font-size: 12px;
      margin-top: 5px;
    }
  }

  .form-buttons {
    display: flex;
    gap: 10px;

    button[type="submit"]:disabled {
      opacity: 0.6;
      cursor: default;
    }
  }

  @media (max-width: $lg-max) {
    padding: 15px;
  }

  @media (max-width: $md-max) {
    padding: 15px;

    .form-row {
      flex-direction: column;
    }

    .form-group {
      margin-right: 0;
      margin-bottom: 15px;
    }

    .form-buttons {
      flex-direction: column;
      gap: 5px;
    }
  }

  @media (max-width: $sm-max) {
    max-width: 100%;
    margin: 10px 0 0;
    padding: 0;

    .add-advertiser-header {
      h2 {
        font-size: 20px;
      }
    }

    padding: 20px 15px;

    .form-group {
      input {
        padding: 8px;
      }
    }

    .form-buttons {
      align-items: center;
    }
  }
}