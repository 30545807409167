.primary-backdrop {
    background: #000!important;
    opacity: 0.5 !important;
}

.transparent-backdrop {
    opacity: 0.1 !important;
}

.full-overlay-panel {
    width: 100%;
    height: 100vh;
}

.selected-ad-notification {
    bottom: 30px;
    right: 60px;
}

@media (max-width: $lg-max) {
    .selected-ad-notification {
        bottom: 15px;
        right: 40px;
    }
}

@media (max-width: $sm-max) {
    .selected-ad-notification {
        right: 24px;
    }
}

@media (max-width: $xs-max) {
    .selected-ad-notification {
        right: 24px;
        right: 0;
        left: 0;
        hadsup-notification {
            margin: auto;
            width: 320px;
            .notification-wrap {
                justify-content: space-between;
                height: 55px;
            }
            .msg {
                width: 100px;
            }
        }
    }
}

@media (max-width: 374.98px) {
    .cdk-overlay-pane {
        width: 100%;
        left: 0 !important;
    }
}
