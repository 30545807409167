.mdc-checkbox__ripple {
    display: none;
}

// mat-checkbox style -----------------------------
.mdc-form-field {
    display: none;
    white-space: nowrap;
}

.mdc-checkbox-disabled {
    opacity: 0.3 !important;
    pointer-events: none;
}

.mat-mdc-checkbox label {
    color: $primary-light-blue !important;
    @include font-small;
}

.mat-mdc-checkbox-checked {
    .mat-mdc-checkbox label {
        color: #33334f!important;
        @include font-small-bold;
    }
    .mdc-checkbox__native-control:hover {
        .mdc-checkbox__background {
            // background-color: $accent-dark-red !important;
            border-radius: 4px;
        }
    }
}

.mdc-checkbox:hover {
    .mat-mdc-checkbox label {
        color: #33334f;
    }
   
}

.mdc-checkbox__checkmark {
    background-color: transparent !important;
    // border: 1px solid #A2B0BD !important;
}
.mdc-checkbox__checkmark-path {
    stroke: $primary-white !important;
    background-color: $accent-cyan-muted !important;
}

.mdc-checkbox__mixedmark {
    stroke: $primary-white!important;
}
.mat-mdc-checkbox{
    .mdc-checkbox__checkmark{
        border-color: #E0525F !important;
    }
}

// mat-radio style ------------------------
.mat-radio-container {
    width: 16px !important;
    height: 16px !important;

    .mat-radio-outer-circle {
        width: 16px !important;
        height: 16px !important;
        border: 1px solid $accent-cyan-muted;
    }
    .mat-radio-inner-circle {
        width: 16px !important;
        height: 16px !important;
        background-color: $accent-cyan !important;
    }
}

.mat-radio-label-content {
    color: $primary-light-blue;
    @include font-small;
}

.mat-radio-checked {
    .mat-radio-label-content {
        color: white;
        @include font-small-bold;
    }
    .mat-radio-outer-circle {
        width: 16px !important;
        height: 16px !important;
        border: 1px solid $accent-cyan-muted !important;
    }
    .mat-radio-inner-circle {
        transform: scale(0.7) !important;
    }
}

.mat-radio-button:hover {
    .mat-radio-inner-circle {
        transform: scale(0.7) !important;
        background-color: $accent-cyan-muted !important;
    }
    .mat-radio-label-content {
        color: white;
    }
}
// add MDC Css Angular 15

.mdc-checkbox__background {
    border: 1px solid #f46872 !important;
    border-radius: 3px !important;
    width: 17px !important;
    height: 17px !important;
    left: 0!important;
}
.mat-mdc-checkbox .mdc-checkbox {
    // padding: 8px !important;
    margin: 0px !important;
    padding-left: 0!important;
    display: flex;
    align-items: center;
    @media(max-width:767px){
        padding-right: 5px!important;
    }
}
.mat-mdc-checkbox label {
    color: $primary-light-blue !important;
    font-size: 14px !important;
    line-height: 18px!important;
    letter-spacing: normal!important;
    color: $primary-dark-color !important;
    font-weight: 400 !important;
}
.mdc-checkbox__ripple {
  opacity: 0 !important; 
}
.mat-mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    background-color: #f46872 !important;
}
button.mat-calendar-body-in-range {
    outline: none;
}
.mat-mdc-focus-indicator {
    position: static !important;
}
::ng-deep.mdc-checkbox__background {
    border-radius: 3px !important;
    border: 1px solid #f46872 !important;
}