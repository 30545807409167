@import "mixins";
@import "variables";

.hadsup-date-picker {
  mat-date-range-input {
      width: 0;
      visibility: hidden;
  }
}

.mat-datepicker-content {
  background-color: $primary-white !important;
  border-radius: 16px !important;
  padding: 16px;
  margin-top: 17px;
}

.mat-calendar.hadsup-calendar {
  width: max-content !important;
  height: max-content !important;

  .mat-calendar-header {
      padding: 0 !important;

      .mat-calendar-controls {
          margin: 0 !important;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
      }
  }

  .mat-calendar-content {
      padding: 0 !important;

      mat-month-view {
          .mat-calendar-table {
              thead.mat-calendar-table-header {
                  tr th {
                    color: $primary-dark-color;
                    @include label-small;
                      padding: 0 !important;
                  }

                  tr:last-child {
                      display: none;
                  }
              }

              tbody.mat-calendar-body {
                  tr:first-child {
                      height: auto !important;

                      td:first-child {
                          visibility: hidden; // remove mat calendar body label for month
                          height: 0px !important;
                          line-height: 0 !important;
                      }
                  }

                  tr td {
                      padding: 0 !important;

                      :hover {
                          border-radius: 100%;
                          div {
                            color: $primary-white;
                          }
                      }

                      .mat-calendar-body-in-preview
                          .mat-calendar-body-cell-preview {
                          border-top-color: transparent !important;
                          border-bottom-color: transparent !important;
                      }

                      &.mat-calendar-body-in-preview,
                      .mat-calendar-body-in-range {
                          div {
                            color: $primary-white;
                          }

                          &::before {
                              background-color: $primary-light-gray-2;
                          }

                          &:hover {
                              background-color: transparent;
                          }
                      }

                      &.mat-calendar-body-preview-start {
                          // preview start date
                          &::before {
                              border-top-left-radius: 100%;
                              border-bottom-left-radius: 100%;
                          }
                      }

                      &.mat-calendar-body-preview-end {
                          // preview end date
                          &::before {
                              border-top-right-radius: 100%;
                              border-bottom-right-radius: 100%;
                          }
                      }

                      &.mat-calendar-body-label:hover {
                          background-color: transparent !important;
                      }

                      div {
                          color: $primary-dark-color;
                          @include font-small-bold;

                          &.mat-calendar-body-selected {
                            color: $primary-white; // selected date color
                            background-color: $primary-dark-color;

                          }
                      }
                  }

                  .mat-calendar-body-in-preview
                      .mat-calendar-body-cell-preview {
                      border: none !important;
                  }
              }
          }
      }

      mat-year-view,
      mat-multi-year-view {
          .mat-calendar-table {
              thead {
                  display: none;
              }

              tbody.mat-calendar-body {
                  tr td {
                      :hover {
                          span.mat-calendar-body-cell-content {
                              background-color: $primary-dark-color !important;
                              border-radius: 18px !important;
                              color: $primary-white !important;
                            }
                      }

                      div {
                          height: 36px !important;
                          line-height: 36px !important;
                          color: $primary-white;
                          @include font-small-bold;
                      }

                      height: 36px !important;
                      padding: 0 !important;

                      &::before,
                      &::after {
                          display: none !important;
                      }
                  }

                  .mat-calendar-body-selected {
                      background-color: $primary-dark-color !important;
                      color: $primary-white !important;
                      border: none !important;
                  }
              }
          }
      }

      mat-year-view {
          tbody tr:first-child {
              display: none !important;
          }
      }
  }

  &-lg {
      @extend .hadsup-calendar;

      .mat-calendar-controls {
          height: 64px !important;
      }

      .mat-calendar-content {
          mat-month-view {
              .mat-calendar-table {
                  thead.mat-calendar-table-header {
                      tr {
                          height: 56px !important;
                      }

                      tr th {
                          width: 48px !important;
                          line-height: 48px !important;
                      }
                  }

                  tbody.mat-calendar-body {
                      tr {
                          height: 48px;
                      }

                      tr:first-child {
                          td {
                              height: 48px !important;
                              line-height: 48px !important;
                          }
                      }

                      tr td {
                          width: 48px !important;
                          line-height: 48px !important;
                      }
                  }
              }
          }

          mat-year-view,
          mat-multi-year-view {
              .mat-calendar-table {
                  tbody.mat-calendar-body {
                      tr {
                          height: 60px;
                          line-height: 60px;
                      }

                      tr td {
                          width: 84px !important;
                      }
                  }
              }
          }
      }
  }

  &-sm {
      @extend .hadsup-calendar;

      .mat-calendar-controls {
          height: 48px !important;
      }

      .mat-calendar-content {
          mat-month-view {
              .mat-calendar-table {
                  thead.mat-calendar-table-header {
                      tr {
                          height: 40px !important;
                      }

                      tr th {
                          width: 40px !important;
                          line-height: 40px !important;
                      }
                  }

                  tbody.mat-calendar-body {
                      tr {
                          height: 40px;
                      }

                      tr:first-child {
                          td {
                              height: 40px;
                              line-height: 40px !important;
                          }
                      }

                      tr td {
                          width: 40px !important;
                          line-height: 40px !important;
                      }
                  }
              }
          }

          mat-year-view,
          mat-multi-year-view {
              .mat-calendar-table {
                  tbody.mat-calendar-body {
                      tr {
                          height: 48px;
                          line-height: 48px;
                      }

                      tr td {
                          width: 70px !important;
                      }
                  }
              }
          }
      }
  }
}

.mat-datepicker-content .mat-calendar {
  width: 100% !important;
}
.mat-datepicker-popup {
  margin-top: -20px;
  transition: all -2.7s;
}

@media (max-width: $lg-max) {
  .mat-datepicker-popup {
      margin-left: -32px;
  }
}

@media (max-width: $sm-max) {
  .mat-datepicker-popup {
      width: 100%;
      left: 0 !important;
      margin-left: 0;
      margin-right: 0;

      .mat-datepicker-content {
          margin: auto;
          margin-top: -16px;
      }
  }
}

.mat-calendar-body-disabled
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
  ) {
  color: rgba(0, 0, 0, 0.38) !important;
}

.mat-calendar-body-selected {
  background-color: $primary-dark-color !important;
  color:$primary-light-gray !important;
  border: none !important;
}

span.mat-calendar-body-cell-content.mat-focus-indicator {
  color: $primary-dark-color;
  @include font-small-bold;
}

.mat-calendar-body-in-preview {
  div {
    color: $primary-white;
  }
  &::after,
  &::before {
      background-color: $primary-light-gray-2 !important;
  }
  &:hover {
      background-color: transparent;
  }
}

button.mat-calendar-body-cell.mat-calendar-body-active.mat-calendar-body-in-preview {
  &::after,
  &::before {
      border-radius: 16px 0px 0px 16px;
  }
}
.mat-calendar-body-cell.mat-calendar-body-preview-end.mat-calendar-body-in-preview,
.mat-calendar-body-cell-content .mat-focus-indicator {
  &::after,
  &::before {
      border-radius: 0px 16px 16px 0;
  }
}
.mat-calendar-body-cell .mat-calendar-body-active.mat-calendar-body-in-preview .mat-calendar-body-preview-end{
&::after,
  &::before {
      border-radius: 0px 16px 16px 0;
  }
}

.mat-calendar-body-cell-content.mat-focus-indicator.mat-calendar-body-selected:focus,:focus-visible {
  outline: none !important;
}
@media (hover: hover) {
    .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
      > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
        .mat-calendar-body-comparison-identical
      ) {
      background-color:$primary-default-dark !important;
      color: $primary-white !important;
    }
  }
  td.mat-calendar-body-label {
    font-size: 0;
    }