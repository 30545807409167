@mixin sm-dropdown-container {
  display: flex;
  flex-direction: column;
  padding: 8px 16px;

  background: #eaecf0;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.66);
  border-radius: 16px;
  margin-top: 4px;
}

.hadsup-dropdown {
  display: flex;
  align-items: center;
  @include font-small-bold;
  color: $primary-default-dark;
  padding: 8px 8px 8px 16px;
  height: 36px;
  border-radius: 50px;
  width: fit-content;
  cursor: pointer;
  margin: 0 auto;
  &:hover,
  &.active {
    color: white;
    background-color: $primary-default-dark;
  }
  &.disabled,
  &[disabled] {
    opacity: 0.3;
  }
}
