@mixin sm-dropdown-container {
  display: flex;
  flex-direction: column;
 
  background: $primary-white;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.66);
  border-radius: 16px;
  margin-top: 4px;
}
.sm-drop {
  .trigger {
    padding: 8px;
    color: $primary-dark-color;
  }

  .itrigger {
    padding: 8px 4px 8px 8px !important;
  }

  &.mat-mdc-menu-panel {
    @include sm-dropdown-container;
    min-height: auto;
    box-shadow: 0px 0.924528px 3.69811px rgb(0 0 0 / 33%);
    border-radius: 8px;
    cursor: pointer;
    .mat-mdc-menu-content {
      padding: 0;
      overflow: hidden
    }
    .loc-setting-inner  {
      padding: 5px 8px;
      .menu-item{
        padding: 7px 10px;
      }
    }
    .menu-item {
      display: block;
      height: auto;
      cursor: pointer;
      min-width:100%;
      padding: 0px;
      color: $primary-dark-color;
      @include font-small;
      font-size: 12px;
      // font-weight: 600;
      border: 0;
      display: flex;
      // padding: 16px ;
      padding: 10px 10px;
      align-items: center;
      i{
        display: flex;
        margin-right: 10px;
        &::before{
          margin: 0;
        }
      }
      &:hover {
        color: $accent-red;
        i{
          &::before{
            color: $accent-red !important;
          }
        }
      }
    }
    .logout{
      border-top: 1px solid $primary-dark-gray-4;
      border-radius: 0;
     
      margin: 0px;
    }
    .divider {
      width: 100%;
      height: 1px;
      background-color: black;
      margin: 4px 0px;
    }
  }
}
